import React, { useState, CSSProperties } from "react";

interface HoverableLinkProps {
  href: string;
  children: React.ReactNode;
  style?: CSSProperties;
  className?: string;
}

const HoverableLink: React.FC<HoverableLinkProps> = ({
  href,
  children,
  style,
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      href={href}
      style={{
        ...style,
        color: isHovered ? "white" : "#00B4D8",
        textDecoration: "none",
      }}
      className={className}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {children}
    </a>
  );
};

export default HoverableLink;
