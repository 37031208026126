import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Container,
  Grid,
  TextField,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import homeMockup from "../../assets/home-mockup.png";
import theme from "../../moodyTheme";

const Hero: React.FC = () => {
  const [email, setEmail] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [location, setLocation] = useState({ latitude: "", longitude: "" });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(
    localStorage.getItem("showEmailForm") !== "submitted"
  );

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIpAddress(response.data.ip);
        return axios.get(
          `https://get.geojs.io/v1/ip/geo/${response.data.ip}.json`
        );
      })
      .then((geoResponse) => {
        const { latitude, longitude } = geoResponse.data;
        setLocation({ latitude, longitude });
      })
      .catch((error) => {
        console.error("Error fetching geo data:", error);
      });
  }, []);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(validateEmail(newEmail));
  };

  const handleSubmit = async () => {
    setIsSubmitAttempted(true);
    setIsLoading(true);

    if (!isEmailValid) {
      setErrorMessage("Please enter a valid email address.");
      setIsLoading(false);
      return;
    }

    try {
      const apiUrl =
        "https://9rf6bjk1o9.execute-api.us-east-1.amazonaws.com/Prod/email-inbound";
      const data = {
        email: email,
        device_type: window.innerWidth <= 768 ? "mobile" : "desktop",
        user_agent: navigator.userAgent,
        ip_address: ipAddress,
        location: location,
        signup_date: new Date().toISOString(),
      };

      const response = await axios.post(apiUrl, data, {
        headers: { "Content-Type": "application/json" },
      });

      if (response.status === 200) {
        setConfirmationMessage("Email submitted successfully!");
        setErrorMessage("");
        setIsSubmitAttempted(false);
        setShowEmailForm(false);
        localStorage.setItem("showEmailForm", "submitted");
      } else {
        setErrorMessage("Error submitting email. Please try again.");
        setConfirmationMessage("");
      }
    } catch (error) {
      setErrorMessage("Error submitting email. Please try again.");
      setConfirmationMessage("");
      console.error("Error submitting email:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        minHeight: "80vh",
        backgroundColor: theme.colors.moodyDarkBackground,
        backgroundImage: `url(${homeMockup})`,
        backgroundSize: { xs: "60%", md: "auto 50%" },
        backgroundPosition: { xs: "center 150px", md: "85% 300px" },
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="lg" sx={{ marginTop: { xs: "500px", md: "300px" } }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", md: "flex-start" },
            justifyContent: { xs: "center", md: "flex-start" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { xs: "center", md: "flex-start" },
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                fontFamily: "Quicksand, sans-serif",
                fontWeight: "bold",
                fontSize: { xs: "30px", md: "48px" },
                lineHeight: { xs: "40px", md: "60px" },
                color: theme.colors.moodyBlue,
                width: { xs: "80%", md: "700px" },
                textAlign: { xs: "left", md: "left" },
              }}
            >
              Revolutionizing Social Media—Power Back to the Creators
            </Typography>

            <Typography
              variant="h6"
              sx={{
                marginBottom: "2rem",
                fontFamily: "Quicksand, sans-serif",
                fontWeight: "medium",
                fontSize: { xs: "18px", md: "26px" },
                color: theme.colors.moodyWhite,
                maxWidth: { xs: "80%", md: "700px" },
              }}
            >
              Your Content, Your Algorithm, Your Space
            </Typography>
          </Box>

          {showEmailForm && !confirmationMessage && (
            <>
              <TextField
                placeholder="E-mail Address"
                variant="standard"
                value={email}
                onChange={handleEmailChange}
                sx={{
                  width: "100%",
                  maxWidth: "350px",
                  marginBottom: "1rem",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  input: {
                    color: theme.colors.moodyWhite,
                    fontSize: "20px",
                    fontFamily: "Poppins, sans-serif",
                    padding: "20px 0",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: theme.colors.moodyWhite,
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: theme.colors.moodyBlue,
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#b0b0b0",
                    opacity: 1,
                    fontSize: "20px",
                    fontFamily: "Poppins, sans-serif",
                  },
                }}
              />
              {isSubmitAttempted && !isEmailValid && (
                <FormHelperText error sx={{ marginBottom: "1rem" }}>
                  Please enter a valid email address.
                </FormHelperText>
              )}

              <Button
                variant="contained"
                size="large"
                onClick={handleSubmit}
                disabled={isLoading}
                sx={{
                  textAlign: "center",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: "500",
                  fontSize: "20px",
                  lineHeight: "35px",
                  letterSpacing: "0.6px",
                  textTransform: "none",
                  backgroundColor: theme.colors.moodyBlue,
                  color: theme.colors.moodyDarkBackground,
                  width: "100%",
                  maxWidth: "250px",
                  paddingTop: "14px",
                  paddingBottom: "14px",
                  marginBottom: "220px",
                  marginTop: "20px",
                  "&:hover": {
                    backgroundColor: theme.colors.darkButtonHover,
                  },
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Join the Revolution"
                )}
              </Button>
            </>
          )}

          {confirmationMessage && (
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "medium",
                fontSize: "20px",
                color: theme.colors.moodyBlue,
                marginTop: "20px",
              }}
            >
              {confirmationMessage}
            </Typography>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
