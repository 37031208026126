import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import mTheme from "../moodyTheme";
import {
  useMediaQuery,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/logo-horizontal-blue.png";
import HoverableLink from "./utils/HoverableLink";
import SendIcon from "@mui/icons-material/Send";

const ButtonAppBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  const fontSizeHeader = isSmallScreen ? "16px" : "18px"; // Adjust font size based on screen size
  const menuItems = (
    <>
      <HoverableLink
        href="#wtf-neptune"
        style={{
          ...mTheme.topAppBarItemStyles,
          outline: "none",
          fontSize: fontSizeHeader, // Adjust font size based on screen size
        }}
        className="hoverable-link"
      >
        WTF Is Neptune?
      </HoverableLink>
      <HoverableLink
        href="#meet-the-team"
        style={{
          ...mTheme.topAppBarItemStyles,
          outline: "none",
          fontSize: fontSizeHeader, // Adjust font size based on screen size
        }}
        className="hoverable-link"
      >
        Who We Are
      </HoverableLink>
      <HoverableLink
        href="#how-different"
        style={{
          ...mTheme.topAppBarItemStyles,
          outline: "none",
          fontSize: fontSizeHeader, // Adjust font size based on screen size
        }}
        className="hoverable-link"
      >
        How We’re Different
      </HoverableLink>
      <HoverableLink
        href="#follow-us"
        style={{
          ...mTheme.topAppBarItemStyles,
          outline: "none",
          fontSize: fontSizeHeader, // Adjust font size based on screen size
        }}
        className="hoverable-link"
      >
        Follow Us
      </HoverableLink>
    </>
  );

  return (
    <Box sx={{ flexGrow: 1, gap: "2rem" }}>
      <AppBar position="fixed" sx={{ backgroundColor: "#0f1719" }}>
        <Toolbar
          sx={{ display: "flex", justifyContent: "center", height: "100px" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "0 auto",
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "flex-start", md: "center" }, // Align logo left on extra small screens, center on medium and up
                gap: "4rem",
                width: "100%",
              }}
            >
              <HoverableLink
                href="#hero"
                style={{
                  display: "flex",
                  alignItems: "center",
                  outline: "none",
                }}
              >
                <img
                  src={logo}
                  alt="Logo"
                  style={{ height: "60px", marginRight: "1rem" }}
                />
              </HoverableLink>
              {!isSmallScreen && (
                <Box sx={{ display: "flex", gap: "5rem" }}>{menuItems}</Box>
              )}
            </Box>
            <IconButton
              href="mailto:info@theneptuneapp.com"
              sx={{
                color: "#0f1719",
                marginLeft: "40px",
                display: { xs: "none", md: "inline-flex" }, // Hide on small screens, show on medium and up
              }}
              aria-label="email"
            >
              <SendIcon
                sx={{
                  transform: "rotate(-45deg)",
                  stroke: "#4FC6DB",
                  strokeWidth: 2,
                }}
              />
            </IconButton>

            {isSmallScreen && (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  sx={{ color: "#4FC6DB" }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon sx={{ fontSize: "76px" }} />{" "}
                  {/* Adjust the font size here */}
                </IconButton>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={handleDrawerToggle}
                  PaperProps={{
                    sx: {
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "bold",
                      fontSize: "14px",
                      backgroundColor: mTheme.colors.moodyDarkBackground,
                      color: mTheme.colors.moodyBlue,
                    },
                  }}
                >
                  <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={handleDrawerToggle}
                    onKeyDown={handleDrawerToggle}
                  >
                    <List>
                      <ListItemButton component="a" href="#wtf-neptune">
                        <ListItemText primary="WTF Is Neptune?" />
                      </ListItemButton>
                      <ListItemButton component="a" href="#meet-the-team">
                        <ListItemText primary="Who We Are" />
                      </ListItemButton>
                      <ListItemButton component="a" href="#how-different">
                        <ListItemText primary="How We’re Different" />
                      </ListItemButton>
                      <ListItemButton component="a" href="#follow-us">
                        <ListItemText primary="Follow Us" />
                      </ListItemButton>
                    </List>
                  </Box>
                </Drawer>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default ButtonAppBar;
