import React, { useState } from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import theme from "../../moodyTheme";
import openHeadMoodyImage from "../../assets/open-head-moody.png";

const WtfNeptune: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const sections = [
    "At Neptune, we’re not just creating a new platform; we’re pioneering a new era of social media. In a world where creativity often takes a backseat to algorithms and ads, we’re flipping the script. Neptune is designed to put the power back into the hands of creators, ensuring that your voice, your art, and your community are at the forefront.",
    "But our vision doesn’t stop there. As the digital landscape evolves, so will Neptune. We’re committed to leading the charge in a future where social media is transparent, inclusive, and truly driven by human connection. Imagine a space where your creative journey isn’t dictated by trends but shaped by your own vision—a platform where innovation meets integrity, and your work gets the recognition it deserves.",
    "Join us as we build a future where creativity reigns supreme, communities thrive, and the digital world becomes a place of endless possibility. Neptune isn’t just another platform; it’s the start of something revolutionary. Be part of the movement. Be part of the future.",
  ];

  return (
    <Box
      id="wtf-neptune"
      sx={{
        backgroundColor: theme.colors.moodyBlue,
        padding: "6rem 2rem",
        scrollPaddingTop: { xs: "80px", md: "80px" }, // Applies scroll offset for both mobile and desktop
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Hashtag on small screens */}
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" }, // Show only on small screens
              textAlign: "center", // Center-align on small screens
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                ...theme.hashTagStyles,
                fontFamily: "Quicksand, sans-serif",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              #WTFIsNeptune?
            </Typography>
          </Grid>

          {/* Image for small and large screens */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              order: { xs: 0, md: 1 }, // Image first on small screens, second on large screens
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" }, // Center on small screens, right-align on large
            }}
          >
            <Box
              component="img"
              src={openHeadMoodyImage}
              alt="Open Head Moody"
              sx={{
                width: "100%",
                height: "auto",
                backgroundSize: "contain",
                maxWidth: { xs: "80%", md: "100%" }, // Adjust image size for small screens
              }}
            />
          </Grid>

          {/* Hashtag and text content on large screens */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              order: { xs: 1, md: 0 }, // Text content second on small screens, first on large screens
              textAlign: { xs: "center", md: "left" }, // Center-align on small screens, left-align on large
            }}
          >
            {/* Hashtag for large screens */}
            <Box
              sx={{
                display: { xs: "none", md: "block" }, // Show only on large screens
                marginBottom: "1rem",
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  ...theme.hashTagStyles,
                  fontFamily: "Quicksand, sans-serif",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                #WTFIsNeptune?
              </Typography>
            </Box>

            {/* Text content */}
            <Typography sx={theme.headerStyles}>
              A Platform Built for Creators, by Creatives
            </Typography>
            <SwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {sections.map((section, idx) => (
                <Typography key={idx} sx={theme.wtfNeptuneBodyStyles}>
                  {section}
                </Typography>
              ))}
            </SwipeableViews>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "flex-start" }, // Center indicators on small screens
                marginTop: "2rem",
                marginBottom: { xs: "4rem", md: 0 }, // Add margin below on small screens
              }}
            >
              {sections.map((_, idx) => (
                <Box
                  key={idx}
                  sx={{
                    width: "60px",
                    height: "5px",
                    backgroundColor: idx === activeStep ? "#FFFFFF" : "#90E0EF", // White when selected
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    transition: "background-color 0.3s", // Smooth transition effect
                  }}
                  onClick={() => handleStepChange(idx)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WtfNeptune;
