import React, { useState } from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import theme from "../../moodyTheme";
import dualMockup from "../../assets/dual-mockup.png";

const WhoWeAre: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const sections = [
    "Neptune isn’t just another social media platform; it’s a bold reimagining of what social media can be. We’re building a space where creators call the shots, where your work gets seen not because of an algorithm but because of its quality and impact. Our platform is designed to empower you to shape your own creative journey, from customizable content feeds to ghost metrics that let your art speak for itself.",
    "But we’re thinking even bigger. As the digital world continues to evolve, so will Neptune. We’re laying the foundation for a future where social media isn’t just about scrolling and liking—it’s about meaningful interaction, genuine community, and a seamless blend of creativity and commerce. Picture a platform that grows with you, adapting to your needs, and expanding its capabilities to support your ever-evolving vision.",
    "With Neptune, we’re not just building a platform; we’re building a future where creators thrive, communities flourish, and innovation knows no bounds. Whether you’re a seasoned pro or just starting out, Neptune is where your creativity meets endless opportunity.",
  ];

  return (
    <Box id="who-we-are" sx={theme.blueSectionStyles}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          {/* Grid item for the hashtag on small screens */}
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", md: "none" }, // Show only on small screens
              textAlign: "center", // Center-align on small screens
              marginBottom: "1rem",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                ...theme.hashTagStyles,
                fontFamily: "Quicksand, sans-serif",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              #WhoWeAre
            </Typography>
          </Grid>

          {/* Grid item for the image */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              order: { xs: 0, md: 1 }, // Image first on small screens, second on large screens
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" }, // Center on small screens, right-align on large
            }}
          >
            <Box
              component="img"
              src={dualMockup}
              alt="Dual Screens"
              sx={{
                width: "100%",
                height: "auto",
                backgroundSize: "contain",
                maxWidth: { xs: "80%", md: "100%" }, // Adjust image size for small screens
              }}
            />
          </Grid>

          {/* Grid item for the hashtag and text content on large screens */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              order: { xs: 1, md: 0 }, // Text content second on small screens, first on large screens
              textAlign: { xs: "center", md: "left" }, // Center-align on small screens, left-align on large
            }}
          >
            {/* Hashtag for large screens */}
            <Box
              sx={{
                display: { xs: "none", md: "block" }, // Show only on large screens
                marginBottom: "1rem",
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={{
                  ...theme.hashTagStyles,
                  fontFamily: "Quicksand, sans-serif",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                #WhoWeAre
              </Typography>
            </Box>

            {/* Text content */}
            <Typography sx={theme.headerStyles}>
              The Next Era of Social Media Starts Here
            </Typography>
            <SwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {sections.map((section, idx) => (
                <Typography key={idx} sx={theme.whoWeAreBodyStyles}>
                  {section}
                </Typography>
              ))}
            </SwipeableViews>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "flex-start" }, // Center indicators on small screens
                marginTop: "2rem",
                marginBottom: { xs: "4rem", md: 0 }, // Add margin below on small screens
              }}
            >
              {sections.map((_, idx) => (
                <Box
                  key={idx}
                  sx={{
                    width: "60px",
                    height: "5px",
                    backgroundColor: idx === activeStep ? "#FFFFFF" : "#90E0EF",
                    marginRight: "0.5rem",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                  }}
                  onClick={() => handleStepChange(idx)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default WhoWeAre;
