import React from "react";
import { Box, Typography, Container, IconButton, Link } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import logo from "../assets/logo-horizontal-blue.png";
import HoverableLink from "./utils/HoverableLink";
import mTheme from "../moodyTheme";

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: mTheme.colors.moodyDarkBackground,
        padding: "2.5rem 0", // Consistent padding for spacing
        borderTop: "4px solid #00B4D8",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "space-between", // Use space-between for alignment
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "1rem", md: "4rem" },
          padding: "1rem 0", // Padding to create space above and below content
        }}
      >
        <HoverableLink href="#hero" style={{ textDecoration: "none" }}>
          <Box
            component="img"
            src={logo}
            alt="Neptune logo"
            sx={{ height: "60px", cursor: "pointer" }}
          />
        </HoverableLink>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "1rem", md: "3rem" },
            alignItems: "center",
            textAlign: { xs: "center", md: "left" }, // Center on small screens, left-align on larger screens
          }}
        >
          <HoverableLink
            href="#wtf-neptune"
            style={{ ...mTheme.topAppBarItemStyles, outline: "none" }}
            className="hoverable-link"
          >
            WTF Is Neptune?
          </HoverableLink>
          <HoverableLink
            href="#meet-the-team"
            style={{ ...mTheme.topAppBarItemStyles, outline: "none" }}
            className="hoverable-link"
          >
            Who We Are
          </HoverableLink>
          <HoverableLink
            href="#how-different"
            style={{ ...mTheme.topAppBarItemStyles, outline: "none" }}
            className="hoverable-link"
          >
            How We’re Different
          </HoverableLink>
          <HoverableLink
            href="#follow-us"
            style={{ ...mTheme.topAppBarItemStyles, outline: "none" }}
            className="hoverable-link"
          >
            Follow Us
          </HoverableLink>
          <IconButton
            href="mailto:info@theneptuneapp.com"
            sx={{ color: mTheme.colors.moodyDarkBackground }}
            aria-label="email"
          >
            <SendIcon
              sx={{
                transform: "rotate(-45deg)",
                stroke: "#4FC6DB",
                strokeWidth: 2,
              }}
            />
          </IconButton>
        </Box>
      </Container>

      <Typography
        variant="body2"
        sx={{ ...mTheme.footerTextStyles, padding: "1rem 0" }}
      >
        © 2024 Neptune &{" "}
        <Link
          href="https://www.fourmilerivertechnologies.com/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: "#4FC6DB", textDecoration: "none" }}
        >
          Four Mile River
        </Link>
        . All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
