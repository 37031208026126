import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import MainPage from "./components/mainPage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#00B4D8",
    },
    secondary: {
      main: "#90E0EF",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <MainPage />
    </ThemeProvider>
  );
};

export default App;
