import React, { useEffect } from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import theme from "../../moodyTheme";

const features = [
  {
    id: 1,
    title: "No Algorithmic Gatekeeping",
    subtitle: "(Unlike YouTube)",
    description:
      "On YouTube, the algorithm decides who gets seen and who doesn’t, often favoring big names and viral content. At Neptune, we’ve eliminated the barriers. Our customizable algorithm allows you to tailor your experience, ensuring you see the content that truly resonates with you.",
  },
  {
    id: 2,
    title: "Real Connections Over Quick Hits",
    subtitle: "(Unlike TikTok)",
    description:
      "TikTok is all about the quick hit—a fleeting moment of attention that’s here and gone in seconds. At Neptune, we’re about building lasting connections. Our platform is designed to foster genuine community and meaningful interaction.",
  },
  {
    id: 3,
    title: "Interactive Creativity Meets Community",
    subtitle: "(Unlike Netflix)",
    description:
      "Netflix offers incredible content, but it’s a one-way street—you watch, they create. Neptune is different. We’re a platform where creativity is interactive, and the community is at the heart of everything.",
  },
  {
    id: 4,
    title: "Transparent, Creator-First Monetization",
    description:
      "Unlike other platforms where monetization is shrouded in mystery or favors the few, Neptune offers transparent, equitable ways for creators to earn from their work.",
  },
  {
    id: 5,
    title: "No Follower Counts—Just Quality",
    description:
      "Unlike platforms that prioritize popularity and follower counts, Neptune’s ghost metrics put the focus back on what really matters: the quality of your content.",
  },
];

const HowDifferent: React.FC = () => {
  useEffect(() => {
    const handleHashChange = () => {
      const yOffset = -100; // Adjust this value to set the offset for scroll position
      const element = document.getElementById(
        window.location.hash.substring(1)
      );
      if (element) {
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    window.addEventListener("hashchange", handleHashChange);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  return (
    <Box
      id="how-different"
      sx={{
        ...theme.blueSectionStyles,
        width: "100%",
        overflow: "hidden",
        paddingBottom: "100px",
        position: "relative",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={theme.featureBoxStyles}>
          <Typography
            sx={{
              ...theme.hashTagStyles,
              textAlign: { xs: "center", md: "left" }, // Center on small screens, left-align on large screens
            }}
          >
            #HowWe’reDifferent
          </Typography>
          <Typography sx={theme.headerStyles}>Why Neptune?</Typography>
          <Typography sx={theme.wtfNeptuneBodyStyles}>
            In a crowded digital world dominated by platforms like YouTube,
            TikTok, and Netflix, Neptune is charting a new course. Here’s how we
            stand out:
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid
              item
              xs={12}
              md={6}
              key={feature.id}
              sx={index % 2 === 1 ? { marginTop: "100px" } : {}}
            >
              <Box sx={theme.featureColumnStyles}>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={theme.featureIdStyles}>
                    0{feature.id}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "4rem",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  >
                    <Typography sx={theme.featureTitleStyles}>
                      {feature.title}{" "}
                      <Typography
                        component="span"
                        sx={theme.featureSubtitleStyles}
                      >
                        {feature.subtitle}
                      </Typography>
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={theme.featureBodyStyles}>
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowDifferent;
