import React from "react";
import { Box, Container, Grid, Typography, Avatar } from "@mui/material";
import ashleyImage from "../../assets/ashley.jpg";
import chrisImage from "../../assets/chris.jpg";
import annieImage from "../../assets/annie.jpg";
import nickImage from "../../assets/nick.jpg";
import shapesImage from "../../assets/shapes.png";
import theme from "../../moodyTheme";

const MeetTheTeam: React.FC = () => {
  const teamMembers = [
    {
      name: "Ashley",
      title: "CO-FOUNDER & CEO",
      description:
        "As the visionary behind Neptune, Ashley leads the charge in redefining social media. With a keen eye for innovation and a deep commitment to empowering creators, she drives the overall strategy and business development. A lifelong musician and singer, Ashley brings her creative passion to every aspect of Neptune. She’s excited about merging social media with the future of entertainment, ensuring that creators have a truly equitable and immersive experience.",
      image: ashleyImage,
    },
    {
      name: "Chris",
      title: "CO-FOUNDER & CTO",
      description:
        "Chris is the technical mastermind behind Neptune, serving as the lead developer and CTO. His expertise in building scalable and user-centric platforms ensures that Neptune’s infrastructure is robust, secure, and cutting-edge. A talented musician himself, Chris is as passionate about music as he is about technology. His dedication to excellence is the backbone of Neptune’s development, driving forward a platform that champions creativity and transparency.",
      image: chrisImage,
    },
    {
      name: "Annie",
      title: "CPO & PROJECT LEAD",
      description:
        "Annie is the organizational force that keeps Neptune moving forward. She collaborates closely with Ashley to oversee all aspects of business development, ensuring that the team’s vision is executed smoothly. With a background in music and a love for creativity, Annie is deeply committed to providing an equitable social media experience for all creators. Her leadership and drive are key to Neptune’s success.",
      image: annieImage,
    },
    {
      name: "Nick",
      title: "CXO & CREATIVE DIRECTOR",
      description:
        "Nick is the creative genius behind Neptune’s user experience. As the Creative Director of our team, he crafts intuitive and engaging interfaces that make Neptune a joy to use. With strategic guidance from Ashley, Nick focuses on designing a platform that is both beautiful and transformative. A musician and artist at heart, Nick shares the team’s passion for innovation in social media and entertainment, and he’s eager to continue shaping Neptune as a vehicle for creative empowerment.",
      image: nickImage,
    },
  ];

  return (
    <Box
      id="meet-the-team"
      sx={{
        position: "relative",
        backgroundColor: theme.colors.moodyDarkBackground,
        padding: "6rem 2rem",
      }}
    >
      <Box
        component="img"
        src={shapesImage}
        alt="Shapes"
        sx={{
          position: "absolute",
          top: "100px",
          right: "100px",
          width: "150px",
          height: "auto",
          display: { xs: "none", md: "block" },
        }}
      />
      <Container maxWidth="lg">
        <Typography
          sx={{
            ...theme.darkHeaderStyles,
            textAlign: "left", // Always left-aligned
            fontSize: { xs: "30px", md: "48px" }, // 30px on small screens, 48px on larger screens
            lineHeight: { xs: "40px", md: "60px" }, // 40px line height on small screens, 60px on larger screens
            marginBottom: { xs: "2rem", md: "4rem" }, // Additional space below the header for small screens
          }}
        >
          Crafting a New Digital World—
          <br /> Meet the Minds Behind Neptune
        </Typography>
        <Grid container spacing={4}>
          {teamMembers.map((member) => (
            <Grid item xs={12} sm={6} md={3} key={member.name}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "flex-start", md: "flex-start" }, // Left-align on small screens and larger screens
                  textAlign: "left", // Ensure all text content is also left-aligned on small screens
                }}
              >
                <Avatar
                  src={member.image}
                  alt={member.name}
                  sx={{
                    width: 166,
                    height: 166,
                    border: `5px solid ${theme.colors.moodyBlue}`,
                    marginBottom: "1rem",
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: theme.colors.moodyWhite,
                    lineHeight: "40px",
                  }}
                >
                  {member.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "18px",
                    marginTop: "0.5rem",
                    fontWeight: "bold",
                    color: theme.colors.moodyBlue,
                    lineHeight: "30px",
                  }}
                >
                  {member.title}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "16px",
                    marginTop: "0.5rem",
                    fontWeight: "medium",
                    color: theme.colors.moodyWhite,
                    lineHeight: "28px",
                  }}
                >
                  {member.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default MeetTheTeam;
