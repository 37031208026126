import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import theme from "../../moodyTheme";
import ghostHeartIcon from "../../assets/ghost-heart.png";
import filterAlgorithmIcon from "../../assets/filter-algo.png";
import monetizationIcon from "../../assets/gem.png";
import communityIcon from "../../assets/community.png";

const FeatureHighlights: React.FC = () => {
  const features = [
    {
      icon: filterAlgorithmIcon,
      title: "Customizable Algorithm",
      description:
        "Control what you see, based on your mood, vibe, or aesthetic",
    },
    {
      icon: ghostHeartIcon,
      title: "Ghost Metrics",
      description: "Focus on the art, not the numbers",
    },
    {
      icon: monetizationIcon,
      title: "Seamless Monetization",
      description: "Fair compensation and meaningful engagement.",
    },
    {
      icon: communityIcon,
      title: "Community-Centric Experience",
      description:
        "Foster genuine connections in an ad-free, safe environment.",
    },
  ];

  return (
    <Box
      id="feature-highlights"
      sx={{
        backgroundColor: "#1A1A1D",
        padding: { xs: "6rem 0", md: "6rem 2rem" },
        color: "#fff",
        overflowX: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Typography
          align="left"
          sx={{
            ...theme.darkHeaderStyles,
            marginLeft: { xs: "1rem", md: "0px" }, // Slight left margin on small screens
            textAlign: "left", // Always left-align the text
          }}
        >
          Feature Highlights
        </Typography>
        <Grid
          container
          spacing={2} // Reduced spacing for smaller screens to fit cards better
          justifyContent="flex-start" // Align cards to the left on small screens
          sx={{
            marginTop: "2rem",
          }}
        >
          {features.map((feature, index) => (
            <Grid
              item
              xs={6} // Display two items per row on small screens
              md={3} // Maintain the existing layout on larger screens
              key={index}
              sx={{
                display: "flex",
                justifyContent: "flex-start", // Align cards to the left on small screens
                alignItems: "center", // Center vertically for small screens
                textAlign: "center", // Center content inside the cards
                padding: { xs: "0 0.5rem", md: "0" }, // Adjust padding on small screens
              }}
            >
              <Card
                sx={{
                  ...theme.cardStyles,
                  width: { xs: "100%", md: "100%" }, // Full width on small screens to prevent overflow
                  textAlign: "center", // Ensure card content is centered
                  background: "rgba(64, 207, 226, 0.11)", // Semi-transparent background
                  borderRadius: "20px", // Set the border-radius for rounded corners
                  boxShadow: "none", // Remove any existing card outline or box-shadow
                  minHeight: "250px", // Set a consistent minimum height for all cards
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Center content vertically inside the card
                  alignItems: "center", // Center content horizontally inside the card
                }}
              >
                <Box
                  component="img"
                  src={feature.icon}
                  alt={feature.title}
                  sx={theme.cardIconStyles}
                />
                <CardContent sx={{ flex: 1, padding: "0.5rem 0" }}>
                  <Typography
                    sx={{
                      ...theme.cardTitleStyle,
                      lineHeight: "1.2em", // Adjust line height
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography sx={theme.cardBodyStyles}>
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default FeatureHighlights;
